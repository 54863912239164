@import 'src/App.scss';

.advantages {
	@media screen and (min-width: 1000px) {
		background: url('../../public/assets/img/image 13.png'),
			url('../../public/assets/img/image 14.png');
		background-size: 400px, 1000px;
		background-position: -200px 400px, calc(100% + 650px) 100px;
		background-repeat: no-repeat;
	}
	@apply bg-black-3;
	.advantages__content {
		@include content();
		@apply flex flex-col gap-y-8;
		.advantages__title {
			@include section-title;
		}
		.advantages__text {
			@include section-text;
			@apply w-full text-center;
			@media screen and (min-width: 520px) {
				@apply text-left;
				width: 500px;
			}
		}
		.advantages__cards {
			@apply grid sm:grid-cols-1 sm:grid-rows-6 md-800:grid-cols-2 md-800:grid-rows-3 xl:grid-cols-3 xl:grid-rows-2 gap-x-5 gap-y-5 justify-items-center;
			.advantages__card {
				background-image: url('../../public/assets/img/card-xs.png');
				background-repeat: no-repeat;
				width: 320px;
				height: 325px;
				@apply flex flex-col p-5 md:p-5 lg:p-7 gap-y-5;
				@media screen and (min-width: 420px) {
					background-image: url('../../public/assets/img/card.png');
					background-repeat: no-repeat;
					width: 387px;
					height: 317px;
				}
				.advantage__icon {
					@apply w-14 h-14;
				}
				.advantage__automat {
					width: 132px;
					height: 132px;
					margin-top: -35px;
					margin-bottom: -40px;
					background-image: url('../../public/assets/img/icon_automat.png');
					background-size: cover;
				}
				.advantage__title {
					@apply text-white-1 font-Caviar font-bold text-lg;
				}
				.advantage__text {
					@apply text-gray-3 font-Caviar text-lg;
				}
			}
		}
	}
}
