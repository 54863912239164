@import 'src/App.scss';

.about {
	@apply relative;

	.automat {
		width: 400px;
		height: 450px;
		background-image: url('../../public/assets/img/image 17.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: left center;
		@apply absolute hidden lg:block z-10 right-0 top-72;
	}
	.about__content {
		@include content();
		.about__title {
			@include section-title;
		}
		.about__text-content {
			min-height: 750px;
			@apply relative flex flex-col justify-center items-center gap-y-7 text-center md:block md:text-left;
			background: linear-gradient(
					to bottom,
					rgba(11, 12, 12, 0),
					rgba(11, 12, 12, 0),
					rgba(11, 12, 12, 1)
				),
				linear-gradient(
					to top,
					rgba(11, 12, 12, 0),
					rgba(11, 12, 12, 0),
					rgba(11, 12, 12, 1)
				),
				url('../../public/assets/img/image 15.png');

			background-position: center center;
			background-size: cover;
			@apply font-Caviar text-xl text-white-1;
			p {
				@apply justify-self-end;
				max-width: 387px;
			}
			.first {
				@apply block md:absolute left-0 top-32;
			}
			.second {
				@apply block md:absolute left-0 bottom-20;
			}
			.third {
				@apply block md:absolute top-10 right-0;
			}
		}
	}
}
