@import 'src/App.scss';

.schedule {
	@apply bg-black-3 mb-52;
	@media screen and (min-width: 1000px) {
		background: url('../../public/assets/img/image\ 9.png'),
			linear-gradient(
				to bottom,
				rgba(11, 12, 12, 0),
				rgba(11, 12, 12, 0),
				rgba(11, 12, 12, 1)
			),
			linear-gradient(
				to left,
				rgba(11, 12, 12, 0),
				rgba(11, 12, 12, 0),
				rgba(11, 12, 12, 1)
			),
			url('../../public/assets/img/image\ 11.png');

		background-repeat: no-repeat;
		background-position: -680px -50px, calc(100% + 500px) 250px;
		background-size: 1000px, 1250px;
	}
	.schedule__content {
		@include content();
		@apply flex flex-col gap-y-8;
		.schedule__title {
			@include section-title();
		}
		.schedule__text {
			max-width: 470px;
			@include section-text;
			@apply text-center md:text-left;
		}
		.schedule__tabs {
			overflow-x: scroll;

			&::-webkit-scrollbar {
				width: 0;
			}
			@apply flex flex-row  justify-center gap-x-3 md:gap-x-5;
			.schedule__tab_enable {
				@apply text-white-1 font-Caviar font-bold block rounded-lg px-6 md:px-9 py-3  lg:px-14 lg:py-4 bg-gray-1 duration-100 text-2xl;
				&:hover {
					@apply bg-red-1 cursor-pointer;
				}
				&.active {
					@apply bg-red-1;
				}
			}
			.schedule__tab_disable {
				@apply text-gray-3 font-Caviar font-bold block rounded-lg px-6 md:px-9 py-3  lg:px-14 lg:py-4 bg-black-2 duration-100 text-2xl;
				&.active {
					@apply bg-red-1;
				}
			}
		}
		.schedule__tab-content {
			@apply flex flex-col items-center gap-y-4 lg:flex-row gap-x-16 lg:justify-center;
			.schedule__card {
				@apply flex flex-col p-4 bg-gray-1 rounded-2xl gap-y-2 w-full md:max-w-card;
				.schedule__img_strekoza {
					@apply rounded-2xl;

					object-fit: cover;
					height: 280px;
					background-image: url('../../public/assets/img/strekoza.png');
					background-size: cover;
					background-position: center center;
				}
				.schedule__img_msk {
					@apply rounded-2xl;

					object-fit: cover;
					height: 280px;
					background-image: url('../../public/assets/img/msk-lounge.jpeg');
					background-size: cover;
					background-position: center center;
				}
				p {
					@apply font-Caviar text-base leading-loose;
					span:first-child {
						@apply text-gray-3;
					}
					span {
						@apply text-white-1;
					}
				}
				a {
					background-image: url('../../public/assets/img/button.png');
					background-repeat: no-repeat;
					background-size: cover;
					@apply block justify-center p-4  mt-4 text-center text-white-1 font-Caviar font-bold text-xl rounded-2xl duration-100;
					&:hover {
						@apply text-black-1;
					}
				}
			}
		}
	}
}
