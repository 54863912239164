@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
	@font-face {
		font-family: 'Morfin';
		font-style: normal;
		font-weight: 400;
		font-display: swap;
		src: url(../public/assets/fonts/MorfinSans.ttf) format('truetype');
	}
	@font-face {
		font-family: 'Caviar';
		font-style: normal;
		font-weight: 400;
		font-display: swap;
		src: url(../public/assets/fonts/CaviarDreams.ttf) format('truetype');
	}
	@font-face {
		font-family: 'Caviar';
		font-style: normal;
		font-weight: 700;
		font-display: swap;
		src: url(../public/assets/fonts/CaviarDreamsBold.ttf) format('truetype');
	}
}
html {
	@apply bg-black-3;
	body {
		@apply bg-black-3;
		#root {
			@apply flex flex-col;
			section {
				margin-bottom: 70px;
			}
		}
	}
}

b {
	color: red;
	font-weight: normal;
}

@mixin content {
	@apply max-w-content my-0 mx-auto p-3 md:p-3;
}

@mixin section-title {
	@apply font-Morfin text-heading-mobile sm:text-heading-tablet-2 lg:text-heading-2 text-white-1;
}
@mixin section-text {
	@apply font-Caviar text-subhead text-gray-3 text-left self-end;
}
