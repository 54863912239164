@import 'src/App.scss';
.slider {
	height: 650px;
	@apply relative;
	.slider__left {
		@apply absolute hidden lg:block left-0 z-20;
		width: 600px;
		height: 650px;
		@media screen and (min-width: 1400px) {
			background: url('../../public/assets/img/image 14.png'),
				url('../../public/assets/img/image 3.png'),
				url('../../public/assets/img/image 6.png');
			background-position: -650px 0, -350px -400px, 30px 470px;
			background-size: 1000px, 800px, 200px;
			background-repeat: no-repeat;
		}
	}
	.slider__right {
		@apply absolute right-0 z-10;
		width: 1200px;
		height: 650px;
		.image0 {
			background-image: url('../../public/assets/img/pograt-v-mafiyu-v-moskve-s-druzyami.png');
			background-size: cover;
			background-position: 460px;
			background-repeat: no-repeat;
			width: 100%;
			height: 650px;
			@media screen and (min-width: 1000px) {
				background-size: cover;
				background-position: 0 bottom !important;
			}
			@media screen and (min-width: 760px) {
				background-size: cover;
				background-position: 250px;
			}
		}
		.image1 {
			background-image: url('../../public/assets/img/gde-i-s-kem-poigrat-v-mafiyu-v-moskovskom-klube.JPG');
			background-size: cover;

			background-position: 450px bottom;
			background-repeat: no-repeat;
			width: 100%;
			height: 650px;
			@media screen and (min-width: 1000px) {
				background-size: cover;
				background-position: 0 !important;
			}
			@media screen and (min-width: 760px) {
				background-size: cover;
				background-position: 0px;
			}
		}
		.image2 {
			background-image: url('../../public/assets/img/igraem-v-gorodskuyu-mafiyu.png');
			background-size: cover;
			background-position: 500px;
			background-repeat: no-repeat;
			width: 100%;
			height: 650px;
			@media screen and (min-width: 1000px) {
				background-size: cover;
				background-position: 0px top !important;
			}
			@media screen and (min-width: 760px) {
				background-size: cover;
				background-position: 150px;
			}
		}

		.image0::after,
		.image1::after,
		.image2::after {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			content: '';
			display: block;
			background-image: linear-gradient(
				to right,
				#0b0c0c 5%,
				transparent 30%
			);
		}

		.image0::before,
		.image1::before,
		.image2::before {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			content: '';
			display: block;
			background-image: linear-gradient(
				to left,
				#0b0c0c 5%,
				transparent 30%
			);
		}
	}
	.slider__content {
		@include content();
		@apply relative z-30 flex flex-col justify-center gap-y-8;

		height: 650px;
		.slider__title {
			@apply text-5xl sm:text-heading-tablet md:text-heading-1 font-Morfin text-white-1 leading-tight z-10;
		}
		.slider__text {
			@apply text-white-1 text-center sm:text-left md:w-text text-2xl md:text-2xl font-Caviar  z-10;
		}
		.slider__button {
			background-image: url('../../public/assets/img/Property 1.png');
			background-repeat: no-repeat;
			background-size: contain;
			width: 268px;
			height: 65px;
			@apply text-white-1 mx-auto sm:mx-0 text-2xl font-Caviar flex rounded-lg justify-center p-4 font-bold duration-100 z-10;
			&:hover {
				@apply text-black-1;
			}
		}
		.slider__pagination {
			@apply flex flex-row absolute bottom-3 left-1/2 gap-x-2 my-0 mx-auto w-16 z-50;

			div.active {
				@apply w-3 h-3 rounded-full bg-white-1;
			}
			div {
				@apply w-3 h-3 rounded-full bg-gray-3 duration-500;
				&:hover {
					@apply bg-white-1 cursor-pointer;
				}
			}
		}
	}
}
