@import 'src/App.scss';

.navigation {
	@apply bg-black-1 p-3 hidden md:block;
	.navigation__content {
		@apply flex flex-row gap-x-5;
		@include content();
		.navigation__link {
			@apply text-white-1 text-base font-Caviar duration-100;
			&:hover {
				@apply text-red-1 cursor-pointer;
			}
		}
		.navigation__telephone {
			@apply lg:visible xl:hidden text-red-1 font-Morfin text-2xl absolute right-2;
		}
	}
}
