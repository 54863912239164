@import 'src/App.scss';
.footer {
	@apply bg-black-2 pb-8;
	.footer__content {
		@include content();
		@apply flex flex-col md:flex-row gap-x-36 relative xs:gap-y-7 xs:items-center lg:items-start md:justify-around lg:justify-between py-5 sm:py-24;
		.footer__main {
			@apply flex flex-col gap-y-12;
			.footer__imgs {
				@apply flex flex-col sm:flex-row items-center justify-center;
				.footer__logo {
					@apply w-20 h-auto mr-1;
				}
				.footer__lettering {
					width: 280px;
					height: 36px;
					@media screen and (min-width: 480px) {
						width: 350px;
						height: 45px;
					}
				}
			}
			p {
				@apply w-full text-gray-3 font-Caviar text-center md:text-left md:w-96;
			}
		}
		.footer__navigation {
			@apply hidden lg:flex flex-col gap-y-4;
			span {
				@apply text-white-1 font-Morfin text-3xl;
			}
			a {
				@apply text-white-1 font-Caviar duration-100;
				&:hover {
					@apply text-red-1 cursor-pointer;
				}
			}
		}
		.footer__contacts {
			@apply flex flex-col gap-y-5 md:gap-y-8 my-7 sm:my-0;
			span {
				@apply text-white-1 font-Morfin text-3xl text-center;
			}
			.footer__phone-number {
				@apply text-red-1 font-Morfin text-2xl text-center;
			}
			.footer__icons {
				@apply flex flex-row gap-x-4 justify-center;

				.header__social-icon {
					@apply p-3 rounded-full block bg-black-1 text-white-1 justify-center duration-100 shrink;
					&:hover {
						@apply bg-red-1;
					}
				}
			}
		}
		.footer__copyright {
			@apply block text-center  sm:absolute  left-1/2 xs:bottom-5  sm:left-5 sm:bottom-3 text-gray-3 font-Caviar;
		}
		.footer__sava {
			@apply block mt-10 xs:mt-0 text-center  sm:absolute  right-1/2 xs:bottom-4  sm:right-5 sm:bottom-3 text-gray-3 font-Caviar;
			a {
				@apply text-red-1;
			}
		}
		.footer__info {
			font-size: 10px;
			@apply absolute text-gray-3 font-Caviar  -bottom-6 sm:-bottom-5 md:-bottom-5 md:left-1/3;
		}
	}
}
