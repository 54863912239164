@import 'src/App.scss';

.form {
	@media screen and (min-width: 1200px) {
		& {
			background: url('../../public/assets//img/image 19.png'),
				url('../../public/assets//img/image 18.png');
			background-repeat: no-repeat;
			background-size: 800px, 1000px;
			background-position: -350px center, calc(100% + 600px) 0;
		}
	}
	.form__content {
		@apply flex flex-col gap-y-8;
		@include content();
		.form__title {
			@include section-title;
		}
		.form__text {
			@include section-text;
			max-width: 488px;
			@apply text-center md:text-left;
		}
		form {
			max-width: 770px;
			@apply flex flex-col gap-y-2 bg-gray-1 rounded-2xl p-10;
			input[type='text'] {
				@apply bg-black-2 text-gray-3 font-Caviar text-lg p-4 rounded-xl;
				&::-webkit-input-placeholder {
					/* WebKit, Blink, Edge */
					color: #858585;
				}
				&:-moz-placeholder {
					/* Mozilla Firefox 4 to 18 */
					color: #858585;
					opacity: 1;
				}
				&::-moz-placeholder {
					/* Mozilla Firefox 19+ */
					color: #858585;
					opacity: 1;
				}
				&:-ms-input-placeholder {
					/* Internet Explorer 10-11 */
					color: #858585;
				}
				&::-ms-input-placeholder {
					/* Microsoft Edge */
					color: #858585;
				}

				&::placeholder {
					/* Most modern browsers support this now. */
					color: #858585;
				}
				&:focus {
					border: none;
					outline: none;
				}
				&.incorrect {
					border: 1px solid red !important;
				}
			}
			textarea {
				max-width: 690px;
				height: 200px;
				resize: none;
				@apply bg-black-2 font-Caviar text-gray-3 rounded-xl p-4;
				&::-webkit-input-placeholder {
					/* WebKit, Blink, Edge */
					color: #858585;
				}
				&:-moz-placeholder {
					/* Mozilla Firefox 4 to 18 */
					color: #858585;
					opacity: 1;
				}
				&::-moz-placeholder {
					/* Mozilla Firefox 19+ */
					color: #858585;
					opacity: 1;
				}
				&:-ms-input-placeholder {
					/* Internet Explorer 10-11 */
					color: #858585;
				}
				&::-ms-input-placeholder {
					/* Microsoft Edge */
					color: #858585;
				}

				&::placeholder {
					/* Most modern browsers support this now. */
					color: #858585;
				}
				&:focus {
					border: none;
					outline: none;
				}
				&.incorrect {
					border: 1px solid red !important;
				}
			}
			button[type='submit'] {
				background-image: url('../../public/assets/img/buttonForm.png');
				height: 65px;
				width: 267px;
				@apply font-Caviar font-bold text-white-1 mt-3 duration-100 text-lg;
				&:hover {
					@apply text-black-1 cursor-pointer;
				}
			}
			span {
				@apply text-gray-3 text-sm block mt-5;
			}
			.form__visible-text {
				@apply font-Caviar text-red-1 block;
			}
			.form__hidden-text {
				@apply hidden;
			}
			.correct-text-visible {
				color: green;
				@apply font-Caviar block text-lg duration-500;
			}
			.correct-text-hidden {
				@apply hidden duration-500;
			}
		}
	}
}
