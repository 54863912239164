@import 'src/App.scss';

.header {
	.header__bottom {
		@apply bg-black-2 md:py-3;
		@media screen and (min-width: 1000px) {
			& {
				background-image: url('../../public/assets/img/image 2.png'),
					url('../../public/assets/img/image 1.png');
				background-position: -400px -150px, calc(100% + 400px) -150px;
				background-attachment: fixed, fixed;
				background-repeat: no-repeat;
				background-size: 800px;
			}
		}

		.header__bottom-content_close {
			@include content();
			@apply flex flex-col md:flex-row items-center gap-x-3 justify-center sm:justify-between md:justify-center lg:justify-between;
			.header__main {
				@apply flex flex-col md:flex-row gap-x-3 items-center my-2;
				.header__logo {
					@apply w-logo-1 sm:w-logo-1 md:w-24 lg:w-28 h-auto;
				}
				.header__lettering {
					@apply block w-full sm:w-lettering-1 md:w-lettering-2 md:h-lettering-2 lg:w-lettering-3 lg:h-lettering-3;
				}
			}
			.header__burger_open {
				position: absolute;
				top: 10px;
				left: 10px;
				background-image: url('../../public/assets/img/Krestik.png');
				background-size: contain;
				background-repeat: no-repeat;
				@apply w-12 h-12 sm:block md:hidden duration-1000;
			}
			.header__burger_close {
				position: absolute;
				top: 10px;
				left: 10px;
				background-image: url('../../public/assets/img/Polosochki.png');

				background-size: contain;
				background-repeat: no-repeat;
				@apply w-12 h-12 sm:block md:hidden duration-1000;
			}
			.header__bottom-icons {
				@apply flex flex-row gap-x-4;
				.header__phone-number {
					@apply text-red-1 font-Morfin hidden xl:block text-3xl md:ml-0 lg:ml-5 pt-2;
				}
				.header__social-icon {
					@apply p-3 rounded-full block bg-black-1 text-white-1 justify-center duration-100 shrink hidden lg:flex;
					&:hover {
						@apply bg-red-1;
					}
				}
			}
			.header__bottom-navigation_close {
				@apply hidden;
			}
			.header__bottom-navigation_open {
				@apply flex-col flex md:hidden;
			}
			.header__navigation-icons_open {
				@apply flex flex-col gap-y-5 items-center;
			}
			.header__navigation-icons_close {
				@apply hidden;
			}
		}
		.header__bottom-content_open {
			@include content();

			@apply flex fixed flex-col md:flex-row items-center gap-x-3 gap-y-12 justify-between lg:justify-between h-screen z-50 bg-black-2;
			.header__main {
				@apply flex flex-col md:flex-row gap-x-3 items-center my-2;
				.header__logo {
					@apply w-logo-1 md:w-24 lg:w-28  h-auto;
				}
				.header__lettering {
					@apply block w-full sm:w-lettering-1 sm:w-lettering-1 sm:h-lettering-1 md:w-lettering-2 md:h-lettering-2 lg:w-lettering-3 lg:h-lettering-3;
				}
			}
			.header__burger_open {
				position: absolute;
				top: 10px;
				left: 10px;
				background-image: url('../../public/assets/img/Krestik.png');
				background-size: contain;
				background-repeat: no-repeat;
				@apply w-12 h-12 sm:block md:hidden duration-1000;
			}
			.header__burger_close {
				position: absolute;
				top: 10px;
				left: 10px;
				background-image: url('../../public/assets/img/Polosochki.png');
				background-size: contain;
				background-repeat: no-repeat;
				@apply w-12 h-12 sm:block md:hidden duration-1000;
			}
			.header__bottom-icons {
				@apply flex flex-row gap-x-4;
				.header__phone-number {
					@apply text-red-1 font-Morfin hidden xl:block text-3xl md:ml-0 lg:ml-5 pt-2;
				}
				.header__social-icon {
					@apply p-3 rounded-full block bg-black-1 text-white-1 justify-center items-center duration-100 shrink hidden lg:flex;
					svg {
						display: block;
						margin: 5px auto;
					}
					&:hover {
						@apply bg-red-1;
					}
				}
			}
			.header__bottom-navigation_close {
				@apply hidden;
			}
			.header__bottom-navigation_open {
				@apply flex-col flex gap-y-10 -mt-32 md:hidden text-center text-white-1 font-Caviar text-2xl;
			}
			.header__navigation-icons_open {
				@apply flex flex-col gap-y-5 items-center md:hidden pb-3;
				.header__phone-number {
					@apply text-red-1 font-Morfin text-3xl;
				}
				.header__navigation-social-icons {
					@apply flex gap-x-5;
					.header__social-icon {
						@apply p-4 rounded-full block bg-black-1 text-white-1 justify-center duration-100  flex;
						&:hover {
							@apply bg-red-1;
						}
					}
				}
			}
			.header__navigation-icons_close {
				@apply hidden;
			}
		}
	}
}
